import { createVNode as _createVNode, unref as _unref, createElementVNode as _createElementVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, withModifiers as _withModifiers, vShow as _vShow, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["aria-expanded"]
const _hoisted_2 = { class: "visually-hidden" }
const _hoisted_3 = ["aria-hidden"]
const _hoisted_4 = ["aria-checked"]
const _hoisted_5 = {
  class: "switch-status",
  "aria-hidden": "true"
}

import { computed, onBeforeUnmount, onMounted, ref } from 'vue';
    import { useStore } from 'vuex';

    import { useI18next } from '@composables/i18next';

    import AccessibilityIcon from '@components/icons/AccessibilityIcon.vue';


    // Setup
    
export default {
  __name: 'AccessibilityMenu',
  setup(__props) {

    const store = useStore();
    const { vT } = useI18next(['global', 'links']);


    // Data
    const isMenuOpen = ref(false);


    // Computed
    const animationEnabled = computed(() => store.getters['ui/isAnimationEnabled']);

    const soundEnabled = computed(() => store.getters['ui/isSoundEnabled']);


    // Methods
    function closeMenu () {
        isMenuOpen.value = false;
    }

    function documentClickHandler (event) {
        if (event.target.closest('#accessibility-menu-button, #accessibility-menu-popup') === null) {
            // If Element#closest() returns null, that means these elements don't exist anywhere up
            // the document chain, and thus the click happened entirely outside of these elements.
            closeMenu();
        }
    }

    function toggleMenu () {
        isMenuOpen.value = !isMenuOpen.value;
    }

    function toggleAnimation () {
        const currentValue = animationEnabled.value;
        store.commit('ui/setAnimationEnabled', {
            animationEnabled: !currentValue,
            name: store.state.app.name,
        });
    }

    function toggleSound () {
        const currentValue = soundEnabled.value;
        store.commit('ui/setSoundEnabled', {
            soundEnabled: !currentValue,
            name: store.state.app.name,
        });
    }


    // Lifecycle
    onMounted(() => {
        window.addEventListener('click', documentClickHandler);
    });

    onBeforeUnmount(() => {
        window.removeEventListener('click', documentClickHandler);
    });

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("button", {
      id: "accessibility-menu-button",
      class: "accessibility-menu-button",
      "aria-controls": "accessibility-menu-popup",
      "aria-expanded": isMenuOpen.value,
      "aria-haspopup": "true",
      onClick: toggleMenu
    }, [
      _createVNode(AccessibilityIcon, { "aria-hidden": "true" }),
      _withDirectives(_createElementVNode("span", _hoisted_2, null, 512), [
        [_unref(vT), 'links:menu']
      ])
    ], 8, _hoisted_1),
    _withDirectives(_createElementVNode("ul", {
      id: "accessibility-menu-popup",
      class: "accessibility-menu",
      "aria-hidden": !isMenuOpen.value
    }, [
      _createElementVNode("li", null, [
        _createElementVNode("button", {
          role: "switch",
          "aria-checked": animationEnabled.value,
          onClick: _withModifiers(toggleAnimation, ["stop"]),
          onBlur: closeMenu
        }, [
          _withDirectives(_createElementVNode("span", null, null, 512), [
            [_unref(vT), 'links:animation']
          ]),
          _createElementVNode("div", _hoisted_5, [
            _withDirectives(_createElementVNode("span", {
              class: _normalizeClass({ active: animationEnabled.value })
            }, null, 2), [
              [_unref(vT), 'links:animation_options.yes']
            ]),
            _cache[0] || (_cache[0] = _createElementVNode("span", null, "/", -1)),
            _withDirectives(_createElementVNode("span", {
              class: _normalizeClass({ active: !animationEnabled.value })
            }, null, 2), [
              [_unref(vT), 'links:animation_options.no']
            ])
          ])
        ], 40, _hoisted_4)
      ])
    ], 8, _hoisted_3), [
      [_vShow, isMenuOpen.value]
    ])
  ], 64))
}
}

}